import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import * as ContactStyles from "./../styles/contact.module.css"
import ContactForm from "../components/form/contactForm"
import { LanguageContext } from "../utils/LanguageContext"

const spanishDescription = 'Puedes enviarnos un correo electrónico para cualquier consulta o pedido. Nos pondremos en contacto con usted lo antes posible.'
const englishDescription = 'You can email us for any question and orders. We will come back to you as soon as we can.'
const SHOWROOM_ADDRESS = 'Avenida Tenor fleta 58, 50007, Zaragoza, España'
const LocationIcon = <svg aria-hidden='true' fill='currentColor' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>

class ContactPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo
          title="Contact"
          pathname={`/contact`}
          keywords={[`contact`, `find us`, `email`, `message`, `get in touch`]}
        />
        <div className={ContactStyles.contactContainer}>
          <div className={ContactStyles.contactFormContainer}>
            <h2>Contact form</h2>
            <div className={ContactStyles.contactFormTitle}>
              <LanguageContext.Consumer>
                { ({ language }) => language === "Spanish" ? spanishDescription : englishDescription }
              </LanguageContext.Consumer>
            </div>
            <ContactForm messageRequired={true} />
          </div>

          <div className={ContactStyles.addressContainer}>
            <h2>
              <LanguageContext.Consumer>
                { ({ language }) => language === "Spanish" ? 'Taller' : 'Showroom' }
              </LanguageContext.Consumer>
            </h2>
            <a href={`https://www.google.com/maps/dir/?api=1&destination=${SHOWROOM_ADDRESS}`} target="_blank">
              {LocationIcon}
              <address>{SHOWROOM_ADDRESS}</address>
            </a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
